// src/components/MapWithMarkerCluster.js
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import L from 'leaflet';
import 'leaflet.markercluster';

import { green, red, yellow } from '@mui/material/colors';

const DefaultIcon = L.icon({
    iconUrl: L.Icon.Default.prototype._getIconUrl('icon'),
    shadowUrl: L.Icon.Default.prototype._getIconUrl('shadow'),
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

const geojsonMarkerOptions = {
    radius: 6,
    fillOpacity: .4
};
  
L.Marker.prototype.options.icon = DefaultIcon;

const myStyle = (feature, filters) => {
    let maxSeverity = 'light';
    if (filters.severity !== 'all') {
        maxSeverity = filters.severity;
    } else if (filters.user !== 'all') {
        feature.properties.info.forEach(item => {
        if (item.user === filters.user) {
            if (item.severity === 'mass') maxSeverity = 'mass';
            else if (item.severity === 'severe' && maxSeverity !== 'mass') maxSeverity = 'severe';
            else if (item.severity === 'light' && maxSeverity !== 'severe' && maxSeverity !== 'mass') maxSeverity = 'light';
        }
        });
    } else {
        feature.properties.info.forEach(item => {
        if (item.severity === 'mass') maxSeverity = 'mass';
        else if (item.severity === 'severe' && maxSeverity !== 'mass') maxSeverity = 'severe';
        else if (item.severity === 'light' && maxSeverity !== 'severe' && maxSeverity !== 'mass') maxSeverity = 'light';
        });
    }
    switch (maxSeverity) {
        case 'mass': return { color: red[700] };
        case 'severe': return { color: yellow[700] };
        case 'light': return { color: green[700] };
        default: return { color: green[700] };
    }
};

const MarkerClusterGroup = ({ data, filters }) => {
  const map = useMap();

  useEffect(() => {
    if (!data) {
      console.error('Datos de geojson no están presentes');
      return;
    }

    const markerClusterGroup = L.markerClusterGroup({
        spiderfyOnMaxZoom: false,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true
    });

    const points = data.flatMap(feature => {
      if (feature.geometry.type === 'Point') {
        const [lon, lat] = feature.geometry.coordinates;
        const marker = L.circleMarker([lat, lon], geojsonMarkerOptions);
        //marker.icon = L.circleMarker(latlng, geojsonMarkerOptions);
        markerClusterGroup.addLayer(marker);
      } else {
        return [];
      }
    });

    map.addLayer(markerClusterGroup);
  
    return () => {
      map.removeLayer(markerClusterGroup);
    };
  }, [map, data]);

  return null;
};

export default MarkerClusterGroup;
