// src/components/DrawerContent.js
import React from 'react';
import { Box, Divider, Button, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material';

const DrawerContent = ({ handleDrawerToggle, filters, handleFilterChange, showHeatmap, handleHeatmapToggle, mapStyle, handleMapStyleChange }) => (
  <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
    <img src={`${process.env.PUBLIC_URL}/SOTERIA_logo.png`} height="26px" alt="Soteria Logo" style={{ margin: '11.5px' }} />
    <Divider />
    <Box sx={{ alignItems: 'center', flexGrow: 1 }}>
      <FormGroup row sx={{ alignItems: 'center', m: 'block', flexGrow: 1, margin: 0 }}>
        <Button variant='text' size='small' sx={{ ml: '10px', display: 'block', marginRight: '8px' }}>Heatmap</Button>
        <FormControlLabel
          control={<Switch checked={showHeatmap} sx={{ marginRight: '-16px' }} onChange={handleHeatmapToggle} />}
        />
      </FormGroup>
    </Box>
    <Box sx={{ alignItems: 'center', flexGrow: 1 }}>
      <Button variant='text' size='small' sx={{ ml: 1, textAlign: 'center', display: 'block' }}>Filters</Button>
      <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
        <InputLabel>Type</InputLabel>
        <Select
          label="Type"
          value={filters.type}
          onChange={handleFilterChange}
          name="type"
          displayEmpty
          inputProps={{ 'aria-label': 'type' }}
        >
          <MenuItem value="all"><em>All</em></MenuItem>
          <MenuItem value="segments">Segments</MenuItem>
          <MenuItem value="intersection">Intersection</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
        <InputLabel>User</InputLabel>
        <Select
          label="User"
          value={filters.user}
          onChange={handleFilterChange}
          name="user"
          inputProps={{ 'aria-label': 'user' }}
        >
          <MenuItem value="all"><em>All</em></MenuItem>
          <MenuItem value="pedestrian">Pedestrian</MenuItem>
          <MenuItem value="cyclist">Cyclist</MenuItem>
          <MenuItem value="motorcycle">Motorcycle</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
        <InputLabel>Severity</InputLabel>
        <Select
          label="Severity"
          value={filters.severity}
          onChange={handleFilterChange}
          name="severity"
          inputProps={{ 'aria-label': 'severity' }}
        >
          <MenuItem value="all"><em>All</em></MenuItem>
          <MenuItem value="light">Light</MenuItem>
          <MenuItem value="severe">Severe</MenuItem>
          <MenuItem value="mass">Mass</MenuItem>
        </Select>
      </FormControl>
    </Box>
  </Box>
);

export default DrawerContent;
