import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import echarts from 'echarts';
import axios from 'axios';
import { Box, Container, Grid, Typography } from '@mui/material';

const cacheKey = 'accidentStatsCache';

const AccidentStatsCharts = () => {
  const [statsData, setStatsData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAndCacheData = async () => {
      try {
        const cachedData = localStorage.getItem(cacheKey);
        if (cachedData) {
          setStatsData(JSON.parse(cachedData));
          setLoading(false);
          return;
        }

        const [response2021, response2022, response2023] = await Promise.all([
          axios.get('https://api.soteria.apps.deustotech.eu/madrid/accidents/stats?year=2021'),
          axios.get('https://api.soteria.apps.deustotech.eu/madrid/accidents/stats?year=2022'),
          axios.get('https://api.soteria.apps.deustotech.eu/madrid/accidents/stats?year=2023')
        ]);

        const data = {
          2021: response2021.data,
          2022: response2022.data,
          2023: response2023.data,
        };

        setStatsData(data);
        localStorage.setItem(cacheKey, JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndCacheData();
  }, []);

  const prepareData = (category) => {
    const years = ['2021', '2022', '2023'];
    const data = {};

    years.forEach(year => {
      Object.keys(statsData[year][category]).forEach(key => {
        if (!data[key]) {
          data[key] = {};
        }
        data[key][year] = statsData[year][category][key];
      });
    });

    return data;
  };

  const getOption = (title, category) => {
    const data = prepareData(category);
    const years = ['2021', '2022', '2023'];

    return {
      title: {
        text: title,
        left: 'center',
        top: 20,
        textStyle: {
          fontSize: 16,
          fontWeight: 'bold'
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        data: Object.keys(data),
        bottom: 10
      },
      grid: {
        top: 60,
        left: '3%',
        right: '4%',
        bottom: 60,
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: Object.keys(data)
      },
      yAxis: {
        type: 'value'
      },
      series: years.map(year => ({
        name: year,
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: Object.keys(data).map(key => data[key][year])
      }))
    };
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Accident Statistics for 2021, 2022, and 2023
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box height="400px">
            <ReactECharts option={getOption('Total Number of Accidents', 'TotalNumberOfAccidents')} style={{ height: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box height="400px">
            <ReactECharts option={getOption('Fatal and Severe Accidents by Involved User', 'FatalAndSevereAccidentsByInvolvedUser')} style={{ height: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box height="400px">
            <ReactECharts option={getOption('Fatal and Severe Accidents by User Type', 'FatalAndSevereAccidentsByUserType')} style={{ height: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box height="400px">
            <ReactECharts option={getOption('Fatal and Severe Accidents by Causes', 'FatalAndSevereAccidentsByCauses')} style={{ height: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box height="400px">
            <ReactECharts option={getOption('Fatal and Severe Accidents by Age Range', 'FatalAndSevereAccidentsByAgeRange')} style={{ height: '100%' }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccidentStatsCharts;
