// src/components/Map.js
import React from 'react';
import { MapContainer, TileLayer, ZoomControl, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.heat';
import 'leaflet.markercluster';
import 'leaflet/dist/leaflet.css';
import FetchGeoData from './FetchGeoData';
import Heatmap from './Heatmap';
import MarkerClusterGroup from './MapWithMarkerCluster';
import { green, red, yellow } from '@mui/material/colors';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

const accessToken = "pk.eyJ1IjoiYXNtb3JlbiIsImEiOiJjamVqcHlwa2UwNHQ2MnhrMWI5YzlhcnEzIn0.RybeyaOxp41has0A19HWzw";

const DefaultIcon = L.icon({
  iconUrl: L.Icon.Default.prototype._getIconUrl('icon'),
  shadowUrl: L.Icon.Default.prototype._getIconUrl('shadow'),
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

L.Marker.prototype.options.icon = DefaultIcon;

const geojsonMarkerOptions = {
  radius: 6,
  fillOpacity: .4
};

const createPopupContent = (feature) => {
  let popupContent = '';
  if (feature.properties) {
    delete feature.properties.id;
    delete feature.properties.hotspotType;
    for (const [key, value] of Object.entries(feature.properties)) {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          popupContent += `<em>${index + 1}.</em> <strong>${item.user.charAt(0).toUpperCase() + item.user.slice(1)}</strong><br>`;
          for (const [subKey, subValue] of Object.entries(item)) {
            popupContent += `&nbsp;&nbsp;&nbsp;&nbsp;<em>${subKey}:</em> ${subValue}<br>`;
          }
          if (index + 1 !== value.length) popupContent += `<div class='break'><br></div>`;
        });
      } else {
        popupContent += `<strong>${key}:</strong> ${value}<br>`;
      }
    }
  }
  return popupContent;
};

const onEachFeature = (feature, layer) => {
  if (feature.properties) {
    const popupContent = createPopupContent(feature);
    layer.bindTooltip(popupContent);
  }
};

const myStyle = (feature, filters) => {
  let maxSeverity = 'light';
  if (filters.severity !== 'all') {
    maxSeverity = filters.severity;
  } else if (filters.user !== 'all') {
    feature.properties.info.forEach(item => {
      if (item.user === filters.user) {
        if (item.severity === 'mass') maxSeverity = 'mass';
        else if (item.severity === 'severe' && maxSeverity !== 'mass') maxSeverity = 'severe';
        else if (item.severity === 'light' && maxSeverity !== 'severe' && maxSeverity !== 'mass') maxSeverity = 'light';
      }
    });
  } else {
    feature.properties.info.forEach(item => {
      if (item.severity === 'mass') maxSeverity = 'mass';
      else if (item.severity === 'severe' && maxSeverity !== 'mass') maxSeverity = 'severe';
      else if (item.severity === 'light' && maxSeverity !== 'severe' && maxSeverity !== 'mass') maxSeverity = 'light';
    });
  }
  switch (maxSeverity) {
    case 'mass': return { color: red[700] };
    case 'severe': return { color: yellow[700] };
    case 'light': return { color: green[700] };
    default: return { color: green[700] };
  }
};

const Map = ({ geoData, setGeoData, filters, showHeatmap, showGrouping, mapStyle }) => (
  <MapContainer center={[40.4168, -3.6908]} zoomControl={false} zoom={15} style={{ height: '100%', width: '100%' }}>
    <TileLayer
      url={`https://api.mapbox.com/styles/v1/${mapStyle}/tiles/{z}/{x}/{y}?access_token=${accessToken}`}
      accessToken={accessToken}
      tileSize={512}
      zoomOffset={-1}
      attribution='&copy; <a href="https://www.mapbox.com/about/maps/">Mapbox</a> contributors'
    />
    <ZoomControl position="bottomright" />
    <FetchGeoData setGeoData={setGeoData} filters={filters} />
    { geoData && (showHeatmap ? <Heatmap data={geoData} /> :
      showGrouping ?
        <MarkerClusterGroup data={geoData} filters={filters} /> :
        <GeoJSON key={JSON.stringify(geoData)} data={geoData} onEachFeature={onEachFeature} style={(feature) => myStyle(feature, filters)} pointToLayer={(feature, latlng) => L.circleMarker(latlng, geojsonMarkerOptions)} />
    ) }
  </MapContainer>
);

export default Map;
