// src/components/FetchGeoData.js
import { useEffect, useCallback } from 'react';
import { useMap } from 'react-leaflet';
import axios from 'axios';

function FetchGeoData({ setGeoData, filters }) {
  const map = useMap();

  const fetchGeoData = useCallback(() => {
    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    let url = `https://api.soteria.apps.deustotech.eu/madrid/hotspots/geo?sw_lon=${sw.lng}&sw_lat=${sw.lat}&ne_lon=${ne.lng}&ne_lat=${ne.lat}`;

    if (filters.type && filters.type !== 'all') {
      url += `&type=${filters.type}`;
    }
    if (filters.user && filters.user !== 'all') {
      url += `&user=${filters.user}`;
    }
    if (filters.severity && filters.severity !== 'all') {
      url += `&severity=${filters.severity}`;
    }

    axios.get(url)
      .then(response => {
        setGeoData(response.data);
        //console.log(response.data);
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
      });
  }, [map, setGeoData, filters]);

  useEffect(() => {
    fetchGeoData();
    map.on('moveend', fetchGeoData);
    return () => {
      map.off('moveend', fetchGeoData);
    };
  }, [map, fetchGeoData, filters]);

  return null;
}

export default FetchGeoData;
