import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet.heat';

function convertLineStringToPoints(lineString, density = 10) {
  const points = [];
  const coords = lineString.coordinates;

  for (let i = 0; i < coords.length - 1; i++) {
    const [lon1, lat1] = coords[i];
    const [lon2, lat2] = coords[i + 1];

    for (let j = 0; j < density; j++) {
      const lat = lat1 + (lat2 - lat1) * (j / density);
      const lon = lon1 + (lon2 - lon1) * (j / density);
      points.push([lat, lon, 0.4]); // Puedes ajustar el valor según tus necesidades
    }
  }

  return points;
}

function Heatmap({ data }) {
  const map = useMap();

  useEffect(() => {
    if (!data) {
      console.error('Datos de geojson no están presentes');
      return;
    }

    const points = data.flatMap(feature => {
      if (feature.geometry.type === 'Point') {
        const [lon, lat] = feature.geometry.coordinates;
        return lat && lon ? [[lat, lon, 2.5]] : []; // Puedes ajustar este valor según tus necesidades
      } else if (feature.geometry.type === 'LineString') {
        return convertLineStringToPoints(feature.geometry);
      } else {
        return [];
      }
    });

    const heatLayer = L.heatLayer(points, { radius: 25, blur: 15 }).addTo(map);

    return () => {
      map.removeLayer(heatLayer);
    };
  }, [data, map]);

  return null;
}

export default Heatmap;
