// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Box, CssBaseline, Toolbar, IconButton, Drawer } from '@mui/material';
import { Button, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Switch } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerContent from './components/DrawerContent';
import Map from './components/Map';
import AccidentStatsCharts from './components/AccidentStatsCharts';
import './App.css';

const drawerWidth = 240;

function App(props) {
  const [geoData, setGeoData] = useState(null);
  const [filters, setFilters] = useState({ type: 'intersection', user: 'all', severity: 'all' });
  const [showHeatmap, setShowHeatmap] = useState(false);
  const [showGrouping, setShowGrouping] = useState(false);
  const [mapStyle, setMapStyle] = useState('mapbox/light-v10');
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleHeatmapToggle = () => {
    setShowHeatmap(!showHeatmap);
  };

  const handleGroupingToggle = () => {
    setShowGrouping(!showGrouping);
  };

  const handleMapStyleChange = (e) => {
    setMapStyle(e.target.value);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <DrawerContent
      handleDrawerToggle={handleDrawerToggle}
      filters={filters}
      handleFilterChange={handleFilterChange}
      showHeatmap={showHeatmap}
      showGrouping={showGrouping}
      handleHeatmapToggle={handleHeatmapToggle}
      mapStyle={mapStyle}
      handleMapStyleChange={handleMapStyleChange}
    />
  );

  return (
    <Router>
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <CssBaseline />
        <AppBar component="nav" sx={{ backgroundColor: 'rgba(255,255,255,.95)', color: '#000000' }}>
          <Toolbar variant='dense'>
            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <IconButton onClick={handleDrawerToggle} size="medium" edge="start" color="inherit" aria-label="menu" sx={{ display: {md: 'none', sm: 'flex'}, marginTop: '1px', mr: 1, ml: -1.5 }}>
                  <MenuIcon />
              </IconButton>
              <img src={`${process.env.PUBLIC_URL}/SOTERIA_logo.png`} height="26px" alt="Soteria Logo" style={{ margin: '-5px 20px 0 -5px' }} />
              <Box sx={{ display: { xs: 'none', sm: 'block' }, flexGrow: 1 }}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <Button key="Home" sx={{ color: '#1C3D72' }}>
                    Home
                  </Button>
                </Link>
                <Link to="/statistics" style={{ textDecoration: 'none' }}>
                  <Button key="Statistics" sx={{ color: '#1C3D72' }}>
                    Statistics
                  </Button>
                </Link>
              </Box>
            </div>
            <Box sx={{ display: {md: 'flex', sm: 'none', xs: 'none'}, alignItems: 'center', flexGrow: 1 }}>
              <Button variant='text' size='small' sx={{ my: 0, display: 'block' }}>Filters</Button>
              <FormControl sx={{ m: 1, minWidth: 120, }} size="small">
                <InputLabel>Type</InputLabel>
                <Select
                  label="Type"
                  value={filters.type}
                  onChange={handleFilterChange}
                  name="type"
                  displayEmpty
                  inputProps={{ 'aria-label': 'type' }}
                >
                  <MenuItem value="all"><em>All</em></MenuItem>
                  <MenuItem value="segments">Segments</MenuItem>
                  <MenuItem value="intersection">Intersection</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>User</InputLabel>
                <Select
                  label="User"
                  value={filters.user}
                  onChange={handleFilterChange}
                  name="user"
                  inputProps={{ 'aria-label': 'user' }}
                >
                  <MenuItem value="all"><em>All</em></MenuItem>
                  <MenuItem value="pedestrian">Pedestrian</MenuItem>
                  <MenuItem value="cyclist">Cyclist</MenuItem>
                  <MenuItem value="motorcycle">Motorcycle</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel>Severity</InputLabel>
                <Select
                  label="Severity"
                  value={filters.severity}
                  onChange={handleFilterChange}
                  name="severity"
                  inputProps={{ 'aria-label': 'severity' }}
                >
                  <MenuItem value="all"><em>All</em></MenuItem>
                  <MenuItem value="light">Light</MenuItem>
                  <MenuItem value="severe">Severe</MenuItem>
                  <MenuItem value="mass">Mass</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: {md: 'flex', sm: 'none', xs: 'none'}, flexGrow: 0 }}>
            <FormGroup row sx={{ alignItems: 'center', m: 'block'}}>
                <Button variant='text' size='small' sx={{ m: 0, display: 'block', marginRight: '8px' }}>Grouping</Button>
                <FormControlLabel
                  control={<Switch checked={showGrouping} sx={{ marginRight: '-16px' }} onChange={handleGroupingToggle} />}
                />
              </FormGroup>

              <FormGroup row sx={{ alignItems: 'center', m: 'block'}}>
                <Button variant='text' size='small' sx={{ m: 0, display: 'block', marginRight: '8px' }}>Heatmap</Button>
                <FormControlLabel
                  control={<Switch checked={showHeatmap} sx={{ marginRight: '-16px' }} onChange={handleHeatmapToggle} />}
                />
              </FormGroup>
            </Box>

            <FormControl sx={{ m: 1, minWidth: 120, marginRight: '-2px' }} size="small">
              <InputLabel>Map Style</InputLabel>
              <Select
                label="Map style"
                value={mapStyle}
                onChange={handleMapStyleChange}
                inputProps={{ 'aria-label': 'map-style' }}
              >
                <MenuItem value="mapbox/light-v10">Light</MenuItem>
                <MenuItem value="mapbox/dark-v10">Dark</MenuItem>
                <MenuItem value="mapbox/streets-v11">Streets</MenuItem>
                <MenuItem value="mapbox/outdoors-v11">Outdoors</MenuItem>
                <MenuItem value="mapbox/satellite-v9">Satellite</MenuItem>
              </Select>
            </FormControl>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { sm: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
        <Routes>
          <Route exact path="/" element = {<Map geoData={geoData} setGeoData={setGeoData} filters={filters} showHeatmap={showHeatmap} showGrouping={showGrouping} mapStyle={mapStyle} />} />
          <Route path="/statistics" element = {<AccidentStatsCharts />} />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
